<div class="bodyWrap">
    <div class="videoWrap">
        <header class="header">
            <div class="header__navwrap">
                <div class="header__navwrap--topnav">
                    <p class="header__navwrap--topnav--text">
                        新車・中古車・整備・修理
                    </p>
                    <button class="toggle button-all" tabindex="1">
                        <div class="toggle__wrap">
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                        </div>
                    </button>
                </div>
                <nav class="header__navwrap--sidenav">
                    <ul class="header__navwrap--sidenav--list">
                        <li class="header__navwrap--sidenav--list--item"> <a href="/" title="ホーム" tabindex="1">ホーム</a> </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/subscription" title="サブスク" tabindex="2">サブスク</a > </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/about" title="代表挨拶" tabindex="2" ontouchstart="">代表挨拶</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/service"
                                title="事業内容"
                                tabindex="3"
                                ontouchstart=""
                                >事業内容</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/news"
                                title="お知らせ・イベント情報"
                                tabindex="4"
                                ontouchstart=""
                                >お知らせ・イベント情報</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/car-lineup"
                                title="在庫一覧"
                                tabindex="5"
                                ontouchstart=""
                                >在庫一覧</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/company-info"
                                title="店舗情報"
                                tabindex="6"
                                ontouchstart=""
                                >店舗情報</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/contact"
                                title="お問い合わせ"
                                tabindex="7"
                                ontouchstart=""
                                >お問い合わせ</a
                            >
                        </li>
                        <li
                            class="header__navwrap--sidenav--list--banner instagram__banner"
                        >
                            <a
                                href="https://www.instagram.com/odagiriauto/"
                                target="_blank"
                                rel="”noopener”"
                                title="ODAGIRI AUTO instagram"
                                tabindex="9"
                                ontouchstart=""
                            >
                                <img
                                    src="/assets/img/instagram.svg"
                                    alt="ODAGIRI AUTO instagramアカウント"
                                />
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <section class="about">
            <div class="about__title">
                <p class="title">ABOUT</p>
                <p class="sub-title">ODAGIRI AUTOについて</p>
            </div>
            <div class="about__wrap">
                <div class="about__wrap--company">
                    <img class="about__wrap--company--img" src="/assets/img/001.jpg" alt="ODAGIRI AUTO" />
                    <div class="about__wrap--company--catch">
                        <h2 class="about__wrap--company--title">
                            安い車は何処にでもある!
                        </h2>
                        <h2 class="about__wrap--company--title">
                            安心と信頼はここにある！
                        </h2>
                    </div>
                    <div class="about__wrap--company--detail">
                        <div class="about__wrap--company--text">
                            <p class="about__wrap--company--text--br">
                                車は生活に必要不可欠なものです。
                            </p>
                            <p class="about__wrap--company--text--br">
                                皆様は長く共に過ごすお車をどのように選びますか？
                            </p>
                            <p class="about__wrap--company--text--br">
                                私たちは売り込みはしません。スタッフの知識を持ってお客様のライフスタイルに合ったお車を一緒にお探しします。
                            </p>
                        </div>
                    </div>
                    <div class="about__wrap--company--detail">
                        <h4 class="about__wrap--company--text--emphasis">
                            【自社整備工場・自社積載車両完備】
                        </h4>
                        <h4 class="about__wrap--company--text--emphasis">
                            【全国納車可能】
                        </h4>
                        <p class="about__wrap--company--text">
                            車検・板金・修理・塗装など、お車に関する事は何なりとご相談下さい。
                        </p>
                        <div class="about__wrap--company--text">
                            <p class="about__wrap--company--text--br">
                                詳しくはお電話にてお問い合わせ下さい。
                            </p>
                            <p class="about__wrap--company--text--br">
                                修理・塗装、レッカー・ロードサービスなど日々のお車での生活もサポートします。 新車・中古車販売・買取・車検・板金などお車に関する事や、当店の場所などご不明な点は何でもお気軽にご相談ください。
                            </p>
                            <p class="about__wrap--company--text--br">
                                お電話・ご来店お待ちしております。
                            </p>
                        </div>
                    </div>
                </div>
                <div class="about__wrap--message">
                    <div class="message__title">
                        <p class="title">GREETING</p>
                        <p class="sub-title">代表挨拶</p>
                    </div>
                    <div class="about__wrap--message--inner">
                        <div class="about__wrap--message--inner--img">
                            <img src="/assets/img/006.JPG" alt="ODAGIRI AUTO 代表挨拶" />
                        </div>
                        <div class="about__wrap--message--inner--text about__wrap--company--detail">
                            <div class="about__wrap--company--text">
                                <p class="about__wrap--company--text--br">
                                    オダギリオートは、私の”車が好きだ”という想いから始まりました。
                                </p>
                                <p class="about__wrap--company--text--br">
                                    元から車のドレスアップやカスタムは趣味でしたが、その趣味が高じて整備もできるようになっていました。 何か商売を始めようと考えた時に、どうせやるなら好きなものを商売にしたいと、車の販売店を始めました。
                                </p>
                                <p class="about__wrap--company--text--br">
                                    全く何もないところから一人で始めましたが、最初の拠点として農家の倉庫を改装する際、出会った大工の職人さんが車好きということがあり、大工をやめて現在までスタッフとして共に働いています。 このようにオダギリオートは車が好きだという人々の想いにより成り立っています。
                                </p>
                                <p class="about__wrap--company--text--br">
                                    スタッフみんなの車への想い、それら全てでお客様に合ったお車を一緒にお探しします。 お客様が理想のお車に出会うことが、私たちの喜びにも繋がっています。 車は必要不可欠なものです。
                                </p>
                                <p class="about__wrap--company--text--br">
                                    せっかく共に長く過ごすものであるなら、より良い形でお客様にお届けしたいと考えています。 旧車から最新車まで、なんでもお気軽にご相談ください。 車好きのスタッフが全力でご要望にお応えします。
                                </p>
                            </div>
                            <div class="about__wrap--message--inner--name">
                                <span class="about__wrap--message--inner--name--position">代表取締役</span
                                >
                                <h3
                                    class="about__wrap--message--inner--name--text"
                                >
                                    小田桐 直樹
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-top-contact></app-top-contact>
        <footer class="footer">
            <p class="footer__copyright">&copy; 2021 ODAGIRI AUTO, INC.</p>
        </footer>
        <video class="video" [muted]="'muted'" autoplay loop playsinline>
            <source src="/assets/img/backgroud_movie.mp4" type="video/mp4" />
        </video>
    </div>
    <div class="bodyWrap__back--left"></div>
    <div class="bodyWrap__back--right"></div>
</div>
<script>
    var timer = 0;
    var currentWidth = window.innerWidth;
    $(window).resize(function () {
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }

        timer = setTimeout(function () {
            location.reload();
        }, 200);
    });
</script>
<script>
    var state = false;
    var scrollpos;
    (function ($) {
        $(".toggle").on("click", function () {
            console.log("toggle");
            $(".toggle").toggleClass("active");
            $(".header__navwrap--sidenav").slideToggle();
        });
    });
</script>
<script>
    var $scrollInt = $("#scroll").offset().top;

    const myFunc = function () {
        var elem = document.getElementById("scroll");
        var wh = window.innerHeight;
        elem.style.height = wh + "px";
    };

    window.addEventListener("load", myFunc);
    window.addEventListener("resize", myFunc);
</script>
<script>
    (function ($) {
        $(".scroll-area").mCustomScrollbar();
    })(jQuery);
</script>
<script>
    if (
        navigator.userAgent.match(/MSIE 10/i) ||
        navigator.userAgent.match(/Trident\/7\./) ||
        navigator.userAgent.match(/Edge\/12\./)
    ) {
        $("html").on("mousewheel", function () {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
</script>