import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { Information, InformationChild, setSignedUrl } from 'src/app/models/information';
import { InformationService } from '../../services/information.service';

declare var $;

import * as moment from 'moment';

@Component({
  selector: 'app-car-show',
  templateUrl: './car-show.component.html',
  styleUrls: ['./car-show.component.scss']
})
export class CarShowComponent implements OnInit {

  information: Information = { childs: [] } as Information;
  private _albums = [];

  constructor(
    private route: ActivatedRoute,
    private informationService: InformationService,
    private _lightbox: Lightbox
  ) {
  }

  async ngOnInit() {
    $(".toggle").on("click", function () {
      $(".toggle").toggleClass("active");
      $(".videoWrap").toggleClass("open");
      $(".header__navwrap--sidenav").slideToggle();
    });

    const id = this.route.snapshot.paramMap.get('id');
    this.information = await this.informationService.getInformation(id);

    const urls = await setSignedUrl(this.information);
    for (let i in urls) {
      this.information.childs[i].signedUrls.push(urls[i]);
    }

    for (let i in this.information.childs) {
      const src = this.information.childs[i].signedUrls[0];
      let album = {
        src: src,
        caption: '',
        thumb: ''
      };

      this._albums.push(album);
    }
  }

  onClickThumb(i: number) {
    this._lightbox.open(this._albums, i);
  }
}
