<div class="bodyWrap">
    <div class="videoWrap">
        <header class="header">
            <div class="header__navwrap">
                <div class="header__navwrap--topnav">
                    <p class="header__navwrap--topnav--text">新車・中古車・整備・修理</p>
                    <button class="toggle button-all" tabindex="1">
                        <div class="toggle__wrap">
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                        </div>
                    </button>
                </div>
                <nav class="header__navwrap--sidenav">
                    <ul class="header__navwrap--sidenav--list">
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/index" title="ホーム" tabindex="2" ontouchstart="">ホーム</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/subscription" title="サブスク" tabindex="2">サブスク</a > </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/about" title="代表挨拶" tabindex="3" ontouchstart="">代表挨拶</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/service" title="事業内容" tabindex="4" ontouchstart="">事業内容</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/news" title="お知らせ・イベント情報" tabindex="5" ontouchstart="">お知らせ・イベント情報</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/car-lineup" title="在庫一覧" tabindex="6" ontouchstart="">在庫一覧</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/company-info" title="店舗情報" tabindex="7" ontouchstart="">店舗情報</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/contact" title="お問い合わせ" tabindex="8" ontouchstart="">お問い合わせ</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--banner
                            instagram__banner">
                            <a href="https://www.instagram.com/odagiriauto/" target="_blank" rel=”noopener” title="ODAGIRI
                                AUTO instagram" tabindex="10" ontouchstart="">
                                <img src="/assets/img/instagram.svg" alt="ODAGIRI
                                    AUTO instagramアカウント">
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <main>
            <section class="carIndex">
                <div class="carIndex__title">
                    <p class="title">CAR LINEUP</p>
                    <p class="sub-title">在庫一覧</p>
                </div>
                <div class="carIndex__wrap">
                    <ul class="carIndex__wrap--list">

                        <li class="carIndex__wrap--list--item" *ngFor="let information of informations">
                            <a class="carIndex__wrap--list--item--link" href="/car-lineup/{{ information.id }}" title="" ontouchstart="">
                                <div class="lineUp__list--item-img" *ngIf="information.childs.length > 0" >
                                    <img class="carIndex__wrap--list--item--link--img" src="{{ information.childs[0].signedUrls[0] }}" alt="{{ information.title }}">
                                </div>

                                <div class="carIndex__wrap--list--item--link--text">
                                    <div class="carIndex__wrap--list--item--link--text--inner">
                                        <p class="carIndex__wrap--list--item--link--text--brand">{{ information.brand }}</p>
                                        <p class="carIndex__wrap--list--item--link--text--name">{{ information.title }}</p>
                                        <div class="carIndex__wrap--list--item--link--text--view">
                                            <span class="carIndex__wrap--list--item--link--text--view--line"></span>
                                            <span class="carIndex__wrap--list--item--link--text--view--btn">VIEW</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li class="carIndex__wrap--list--item">
                            <a class="carIndex__wrap--list--item--link" href="/car-lineup" title="" ontouchstart="">
                                <img class="carIndex__wrap--list--item--link--img" src="/assets/img/carLineup.jpg" alt="">
                                <div class="carIndex__wrap--list--item--link--text">
                                    <div class="carIndex__wrap--list--item--link--text--inner">
                                        <p class="carIndex__wrap--list--item--link--text--brand">トヨタ</p>
                                        <p class="carIndex__wrap--list--item--link--text--name">ポルテ</p>
                                        <div class="carIndex__wrap--list--item--link--text--view">
                                            <span class="carIndex__wrap--list--item--link--text--view--line"></span>
                                            <span class="carIndex__wrap--list--item--link--text--view--btn">VIEW</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="carIndex__wrap--list--item">
                            <a class="carIndex__wrap--list--item--link" href="/car-lineup" title="" ontouchstart="">
                                <img class="carIndex__wrap--list--item--link--img" src="/assets/img/carLineup.jpg" alt="">
                                <div class="carIndex__wrap--list--item--link--text">
                                    <div class="carIndex__wrap--list--item--link--text--inner">
                                        <p class="carIndex__wrap--list--item--link--text--brand">トヨタ</p>
                                        <p class="carIndex__wrap--list--item--link--text--name">ポルテ</p>
                                        <div class="carIndex__wrap--list--item--link--text--view">
                                            <span class="carIndex__wrap--list--item--link--text--view--line"></span>
                                            <span class="carIndex__wrap--list--item--link--text--view--btn">VIEW</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="carIndex__wrap--list--item">
                            <a class="carIndex__wrap--list--item--link" href="/car-lineup" title="" ontouchstart="">
                                <img class="carIndex__wrap--list--item--link--img" src="/assets/img/carLineup.jpg" alt="">
                                <div class="carIndex__wrap--list--item--link--text">
                                    <div class="carIndex__wrap--list--item--link--text--inner">
                                        <p class="carIndex__wrap--list--item--link--text--brand">トヨタ</p>
                                        <p class="carIndex__wrap--list--item--link--text--name">ポルテ</p>
                                        <div class="carIndex__wrap--list--item--link--text--view">
                                            <span class="carIndex__wrap--list--item--link--text--view--line"></span>
                                            <span class="carIndex__wrap--list--item--link--text--view--btn">VIEW</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="carIndex__wrap--list--item">
                            <a class="carIndex__wrap--list--item--link" href="/car-lineup" title="" ontouchstart="">
                                <img class="carIndex__wrap--list--item--link--img" src="/assets/img/carLineup.jpg" alt="">
                                <div class="carIndex__wrap--list--item--link--text">
                                    <div class="carIndex__wrap--list--item--link--text--inner">
                                        <p class="carIndex__wrap--list--item--link--text--brand">トヨタ</p>
                                        <p class="carIndex__wrap--list--item--link--text--name">ポルテポルテポルテポルテポルテ</p>
                                        <div class="carIndex__wrap--list--item--link--text--view">
                                            <span class="carIndex__wrap--list--item--link--text--view--line"></span>
                                            <span class="carIndex__wrap--list--item--link--text--view--btn">VIEW</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="carIndex__wrap--list--item">
                            <a class="carIndex__wrap--list--item--link" href="/car-lineup" title="" ontouchstart="">
                                <img class="carIndex__wrap--list--item--link--img" src="/assets/img/carLineup.jpg" alt="">
                                <div class="carIndex__wrap--list--item--link--text">
                                    <div class="carIndex__wrap--list--item--link--text--inner">
                                        <p class="carIndex__wrap--list--item--link--text--brand">トヨタ</p>
                                        <p class="carIndex__wrap--list--item--link--text--name">ポルテ</p>
                                        <div class="carIndex__wrap--list--item--link--text--view">
                                            <span class="carIndex__wrap--list--item--link--text--view--line"></span>
                                            <span class="carIndex__wrap--list--item--link--text--view--btn">VIEW</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
            <section class="topContact">
                <div class="topContact__wrap">
                    <h1 class="topContact__wrap--head">
                        <p class="topContact__wrap--head--title title">CONTACT</p>
                        <p class="topContact__wrap--head--sub sub-title">お問い合わせ</p>
                    </h1>
                    <div class="topContact__wrap--grid">
                        <div class="topContact__wrap--grid--call">
                            <a class="topContact__wrap--grid--call--box" href="tel:0173-46-2598" ontouchstart="" tabindex="11">
                                <div class="topContact__wrap--grid--call--box--flex">
                                    <div class="topContact__wrap--grid--call--box--flex--img">
                                        <img src="/assets/img/mobile.png" alt="電話でお問い合わせ">
                                    </div>
                                    <span class="topContact__wrap--grid--call--box--flex--text">お電話でのお問い合わせ</span>
                                </div>
                            </a>
                        </div>
                        <div class="topContact__wrap--grid--mail">
                            <a class="topContact__wrap--grid--mail--box" href="/contact" tabindex="12" ontouchstart="">
                                <div class="topContact__wrap--grid--mail--box--flex">
                                    <div class="topContact__wrap--grid--mail--box--flex--img">
                                        <img src="/assets/img/mail.png" alt="メールでお問い合わせ">
                                    </div>
                                    <span class="topContact__wrap--grid--mail--box--flex--text">フォームからお問い合わせ</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <footer class="footer">
            <p class="footer__copyright">&copy; 2021 ODAGIRI AUTO, INC.</p>
        </footer>
        <video class="video" [muted]="'muted'" autoplay loop playsinline>
            <source src="assets/img/backgroud_movie.mp4" type="video/mp4">
        </video>
    </div>
    <div class="bodyWrap__back--left"></div>
    <div class="bodyWrap__back--right"></div>
</div>

<script>
    var timer = 0;
    var currentWidth = window.innerWidth;
    $(window).resize(function() {
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }

        timer = setTimeout(function() {
            location.reload();
        }, 200);

    });
</script>
<script>
    var state = false;
    var scrollpos;
    $('.toggle').on('click', function() {
        $('.toggle').toggleClass('active');
        $('.header__navwrap--sidenav').slideToggle();
    });
</script>
<script>
    var $scrollInt = $("#scroll").offset().top;

    const myFunc = (function() {
        var elem = document.getElementById('scroll');
        var wh = window.innerHeight;
        elem.style.height = wh + 'px';
    });

    window.addEventListener('load', myFunc);
    window.addEventListener('resize', myFunc);
</script>
<script>
    (function($) {
        $(".scroll-area").mCustomScrollbar();
    })(jQuery);
</script>
<script>
    if (navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
        $('html').on("mousewheel", function() {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
</script>
<app-loading></app-loading>