import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Nl2BrPipeModule } from 'nl2br-pipe';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    Nl2BrPipeModule 
  ]
})
export class SharedModule { }
