import { Component, OnInit, AfterContentInit } from '@angular/core';
import { InformationService } from '../../services/information.service';
import { Information, setSignedUrl } from 'src/app/models/information';

import * as moment from 'moment';
import Lity from 'lity';

declare var $;
declare var Swiper;

@Component({
  selector: 'app-car-lineup',
  templateUrl: './car-lineup.component.html',
  styleUrls: ['./car-lineup.component.scss']
})
export class CarLineupComponent implements OnInit, AfterContentInit {

  informations: Information[];

  constructor(private informationService: InformationService) { }

  async ngOnInit() {
    $(".toggle").on("click", function () {
      $(".toggle").toggleClass("active");
      $(".videoWrap").toggleClass("open");
      $(".header__navwrap--sidenav").slideToggle();
    });

    this.informations = await this.informationService.getInformations('stock', 200, moment().format('YYYY/MM/01'));
    for (let info of this.informations) {
      const urls = await setSignedUrl(info);
      for (let i in urls) {
        info.childs[i].signedUrls.push(urls[i]);
      }
    }
  }

  ngAfterContentInit() {
    var swiper = new Swiper(".swiper-container", {
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });
  }
}
