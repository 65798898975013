import { Injectable } from '@angular/core';

import { Contact } from '../models/contact';

import { API } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor() { }

  postContact(contact: Contact): Promise<any> {
    contact.type = 'お問い合わせ';
    const params = {
      body: contact
    };
    return API.post('odagiriautoapi', `/contacts`, params);
  }
}
