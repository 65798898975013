<div class="bodyWrap">
    <div class="videoWrap">
        <header class="header">
            <div class="header__navwrap">
                <div class="header__navwrap--topnav">
                    <p class="header__navwrap--topnav--text">
                        新車・中古車・整備・修理
                    </p>
                    <button class="toggle button-all" tabindex="1">
                        <div class="toggle__wrap">
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                        </div>
                    </button>
                </div>
                <nav class="header__navwrap--sidenav">
                    <ul class="header__navwrap--sidenav--list">
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/" title="ホーム" tabindex="2" ontouchstart="">ホーム</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/subscription" title="サブスク" tabindex="2">サブスク</a > </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/about"
                                title="代表挨拶"
                                tabindex="3"
                                ontouchstart=""
                                >代表挨拶</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/service"
                                title="事業内容"
                                tabindex="4"
                                ontouchstart=""
                                >事業内容</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/news"
                                title="お知らせ・イベント情報"
                                tabindex="5"
                                ontouchstart=""
                                >お知らせ・イベント情報</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/car-lineup"
                                title="在庫一覧"
                                tabindex="6"
                                ontouchstart=""
                                >在庫一覧</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/company-info"
                                title="店舗情報"
                                tabindex="7"
                                ontouchstart=""
                                >店舗情報</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/contact"
                                title="お問い合わせ"
                                tabindex="8"
                                ontouchstart=""
                                >お問い合わせ</a
                            >
                        </li>
                        <li
                            class="header__navwrap--sidenav--list--banner instagram__banner"
                        >
                            <a
                                href="https://www.instagram.com/odagiriauto/"
                                target="_blank"
                                rel="”noopener”"
                                title="ODAGIRI AUTO instagram"
                                tabindex="10"
                                ontouchstart=""
                            >
                                <img
                                    src="/assets/img/instagram.svg"
                                    alt="ODAGIRI AUTO instagramアカウント"
                                />
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <section class="company-info">
            <div class="company-info__title">
                <p class="title">CONPANY-INFO</p>
                <p class="sub-title">店舗情報</p>
            </div>
            <div class="company-info__wrap">
                <div class="company-info__wrap--box">
                    <h2 class="company-info__wrap--box--name">小曲店</h2>
                    <div class="company-info__wrap--box--contents">
                        <img class="company-info__wrap--box--contents--img" src="/assets/img/001.jpg" alt="オダギリオート 小曲店" />
                        <ul class="company-info__wrap--box--contents--list">
                            <li class="company-info__wrap--box--contents--list--item">
                                <span class="company-info__wrap--box--contents--list--item--title">住所</span
                                >
                                <span
                                    class="company-info__wrap--box--contents--list--item--text"
                                    >青森県五所川原市大字小曲字沼田97-67</span
                                >
                            </li>
                            <li
                                class="company-info__wrap--box--contents--list--item"
                            >
                                <span
                                    class="company-info__wrap--box--contents--list--item--title"
                                    >電話番号</span
                                >
                                <span
                                    class="company-info__wrap--box--contents--list--item--text"
                                    >0173-26-6033</span
                                >
                            </li>
                            <li
                                class="company-info__wrap--box--contents--list--item"
                            >
                                <span
                                    class="company-info__wrap--box--contents--list--item--title"
                                    >FAX番号</span
                                >
                                <span
                                    class="company-info__wrap--box--contents--list--item--text"
                                    >0173-26-6536</span
                                >
                            </li>
                            <li
                                class="company-info__wrap--box--contents--list--item"
                            >
                                <span
                                    class="company-info__wrap--box--contents--list--item--title"
                                    >営業時間</span
                                >
                                <span
                                    class="company-info__wrap--box--contents--list--item--text"
                                    >10:00 〜 19:00</span
                                >
                            </li>
                            <li
                                class="company-info__wrap--box--contents--list--item"
                            >
                                <span
                                    class="company-info__wrap--box--contents--list--item--title"
                                    >定休日</span
                                >
                                <span
                                    class="company-info__wrap--box--contents--list--item--text"
                                    >年中無休</span
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="company-info__wrap--box--img">
                        <img
                            class="company-info__wrap--box--img--01"
                            src="/assets/img/002.jpg"
                            alt="オダギリオート 小曲店"
                        />
                        <img
                            class="company-info__wrap--box--img--02"
                            src="/assets/img/003.jpg"
                            alt="オダギリオート 小曲店"
                        />
                        <img
                            class="company-info__wrap--box--img--03"
                            src="/assets/img/004.jpg"
                            alt="オダギリオート 小曲店"
                        />
                    </div>
                    <iframe
                        class="company-info__wrap--box--map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.834439419575!2d140.43089430567665!3d40.809631809456555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9bbab5a0c8733f%3A0x21ce933b8e99c62!2z44CSMDM3LTAwNzYg6Z2S5qOu55yM5LqU5omA5bed5Y6f5biC5bCP5puy5rK855Sw77yZ77yX4oiS77yW77yX!5e0!3m2!1sja!2sjp!4v1610948102337!5m2!1sja!2sjp"
                        width="600"
                        height="450"
                        frameborder="0"
                        style="border: 0"
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                    ></iframe>
                </div>
                <div class="company-info__wrap--box">
                    <h2 class="company-info__wrap--box--name">本店</h2>
                    <div class="company-info__wrap--box--contents">
                        <img
                            class="company-info__wrap--box--contents--img"
                            src="/assets/img/005.jpg"
                            alt="オダギリオート 本店"
                        />
                        <ul class="company-info__wrap--box--contents--list">
                            <li
                                class="company-info__wrap--box--contents--list--item"
                            >
                                <span
                                    class="company-info__wrap--box--contents--list--item--title"
                                    >住所</span
                                >
                                <span
                                    class="company-info__wrap--box--contents--list--item--text"
                                    >青森県つがる市稲垣町福富隅田川54-1</span
                                >
                            </li>
                            <li
                                class="company-info__wrap--box--contents--list--item"
                            >
                                <span
                                    class="company-info__wrap--box--contents--list--item--title"
                                    >電話番号</span
                                >
                                <span
                                    class="company-info__wrap--box--contents--list--item--text"
                                    >0173-46-2598</span
                                >
                            </li>
                            <li
                                class="company-info__wrap--box--contents--list--item"
                            >
                                <span
                                    class="company-info__wrap--box--contents--list--item--title"
                                    >FAX番号</span
                                >
                                <span
                                    class="company-info__wrap--box--contents--list--item--text"
                                    >0173-46-2603</span
                                >
                            </li>
                            <li
                                class="company-info__wrap--box--contents--list--item"
                            >
                                <span
                                    class="company-info__wrap--box--contents--list--item--title"
                                    >営業時間</span
                                >
                                <span
                                    class="company-info__wrap--box--contents--list--item--text"
                                    >9:00 〜 18:00</span
                                >
                            </li>
                            <li
                                class="company-info__wrap--box--contents--list--item"
                            >
                                <span
                                    class="company-info__wrap--box--contents--list--item--title"
                                    >定休日</span
                                >
                                <span
                                    class="company-info__wrap--box--contents--list--item--text"
                                    >年中無休</span
                                >
                            </li>
                        </ul>
                    </div>
                    <iframe
                        class="company-info__wrap--box--map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3015.697434307989!2d140.38897151541065!3d40.90045147931187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9bb92da8724727%3A0xa5241953b8212f0a!2z44CSMDM3LTAxMDUg6Z2S5qOu55yM44Gk44GM44KL5biC56iy5Z6j55S656aP5a-M6ZqF55Sw5bed77yV77yU4oiS77yR!5e0!3m2!1sja!2sjp!4v1610948960806!5m2!1sja!2sjp"
                        width="600"
                        height="450"
                        frameborder="0"
                        style="border: 0"
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                    ></iframe>
                </div>
            </div>
        </section>
        <app-top-contact></app-top-contact>
        <footer class="footer">
            <p class="footer__copyright">&copy; 2021 ODAGIRI AUTO, INC.</p>
        </footer>
        <video class="video" [muted]="'muted'" autoplay loop playsinline>
            <source src="/assets/img/backgroud_movie.mp4" type="video/mp4" />
        </video>
    </div>
    <div class="bodyWrap__back--left"></div>
    <div class="bodyWrap__back--right"></div>
</div>
<script>
    var timer = 0;
    var currentWidth = window.innerWidth;
    $(window).resize(function () {
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }

        timer = setTimeout(function () {
            location.reload();
        }, 200);
    });
</script>
<script>
    var state = false;
    var scrollpos;
    $(".toggle").on("click", function () {
        $(".toggle").toggleClass("active");
        $(".header__navwrap--sidenav").slideToggle();
    });
</script>
<script>
    var $scrollInt = $("#scroll").offset().top;

    const myFunc = function () {
        var elem = document.getElementById("scroll");
        var wh = window.innerHeight;
        elem.style.height = wh + "px";
    };

    window.addEventListener("load", myFunc);
    window.addEventListener("resize", myFunc);
</script>
<script>
    (function ($) {
        $(".scroll-area").mCustomScrollbar();
    })(jQuery);
</script>
<script>
    if (
        navigator.userAgent.match(/MSIE 10/i) ||
        navigator.userAgent.match(/Trident\/7\./) ||
        navigator.userAgent.match(/Edge\/12\./)
    ) {
        $("html").on("mousewheel", function () {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
</script>