import { Component, OnInit } from '@angular/core';
import { InformationService } from '../../services/information.service';

import * as moment from 'moment';
import { Information, setSignedUrl } from 'src/app/models/information';

declare var $;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  informations: Information[];
  stocks: Information[];

  constructor(private informationService: InformationService) { }

  async ngOnInit() {
    $(".toggle").on("click", function () {
      $(".toggle").toggleClass("active");
      $(".videoWrap").toggleClass("open");
      $(".header__navwrap--sidenav").slideToggle();
    });

    $(".scroll-area").mCustomScrollbar();
    $(".scroll-content").mCustomScrollbar({
      theme: "inset-3-dark",
    });

    $(".header__navwrap").animate({ visibility: "visible" }, 3000);

    this.informations = await this.informationService.getInformations('news_index', 10, moment().format('YYYY/MM/01'));
    console.log(this.informations);

    this.stocks = await this.informationService.getInformations('stock', 2, moment().format('YYYY/MM/01'));
    for (let info of this.stocks) {
      const urls = await setSignedUrl(info);
      for (let i in urls) {
        info.childs[i].signedUrls.push(urls[i]);
      }
    }
  }
}
