<div class="bodyWrap">
    <div class="videoWrap">
        <header class="header">
            <div class="header__navwrap">
                <div class="header__navwrap--topnav">
                    <p class="header__navwrap--topnav--text">
                        オダギリオートのサブスク
                    </p>
                    <button class="toggle button-all" tabindex="1">
                        <div class="toggle__wrap">
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                        </div>
                    </button>
                </div>
                <nav class="header__navwrap--sidenav">
                    <ul class="header__navwrap--sidenav--list">
                        <li class="header__navwrap--sidenav--list--item"> <a href="/" title="ホーム" tabindex="1">ホーム</a> </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/subscription" title="サブスク" tabindex="2">サブスク</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/about" title="代表挨拶" tabindex="3">代表挨拶</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/service" title="事業内容" tabindex="4" >事業内容</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/news" title="お知らせ・イベント情報" tabindex="5" >お知らせ・イベント情報</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/car-lineup" title="在庫一覧" tabindex="6" >在庫一覧</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/company-info" title="店舗情報" tabindex="7" >店舗情報</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/contact" title="お問い合わせ" tabindex="8" >お問い合わせ</a > </li>
                        <li class="header__navwrap--sidenav--list--banner instagram__banner" >
                            <a href="https://www.instagram.com/odagiriauto/" target="_blank" rel="”noopener”" title="ODAGIRI AUTO instagram" tabindex="10" ontouchstart="" >
                                <img src="/assets/img/instagram.svg" alt="ODAGIRI AUTO instagramアカウント" />
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <section class="subsc">
            <div class="subsc__title">
                <p class="title">SUBSCRIPTION</p>
                <p class="sub-title">オダギリオートのサブスク</p>
            </div>

            <div class="subsc__wrap">
                <div class="subsc__wrap--box">
                    <div class="subsc__wrap--inner">
                        <ul class="subsc__wrap--inner--list">
                            <li class="subsc__wrap--inner--list--item">
                                <div class="subsc__wrap--inner--list--item--img subsc-01"></div>
                                <div class="subsc__wrap--inner--list--item--contents">
                                    <h2 class="subsc__wrap--inner--list--item--contents--title">
                                        オダギリオートの<br>サブスクリプション
                                    </h2>
                                    <p class="subsc__wrap--inner--list--item--contents--text">
                                        当店では密かにサブスク（サブスクリプション）のサービスも行なっています。<br> 様々なお得なプランがありますが、お客様のご要望に合わせた、お客様のご利用方法に最適なサービスを提供させていただきます。
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <section class="subsc-section">
                        <div class="subsc-section__border">
                            <img src="/assets/img/border-parts.png">
                        </div>
                        <h2 class="subsc-header">
                            <img src="/assets/img/subsc-01.png">
                            <div class="subsc-header__labels">
                                <div>新車</div>
                                <div>月々1.1万円<span class="font-small">(税込)</span>プラン</div>
                            </div>
                            <div class="subsc-header__desc">
                                月々1.1万円(税込)から新車に乗ることが出来るプランです。<br> これらが全てコミコミ!
                            </div>
                        </h2>

                        <ul class="subsc-list">
                            <li class="subsc-list__item">車検代</li>
                            <li class="subsc-list__item">車検整備</li>
                            <li class="subsc-list__item">登録費用</li>
                            <li class="subsc-list__item">自動車税</li>
                            <li class="subsc-list__item">取得税</li>
                            <li class="subsc-list__item">オイル交換</li>
                            <li class="subsc-list__item">重量税</li>
                            <li class="subsc-list__item">自賠責</li>
                            <li class="subsc-list__item">フロアマット</li>
                            <li class="subsc-list__item">ドアバイザー</li>
                            <li class="subsc-list__item">洗車</li>
                        </ul>
                    </section>

                    <section class="subsc-section">
                        <div class="subsc-section__border">
                            <img src="/assets/img/border-parts.png">
                        </div>
                        <h2 class="subsc-header">
                            <img src="/assets/img/subsc-02.png">
                            <div class="subsc-header__labels">
                                <div>選べる</div>
                                <div>3つのプラン</div>
                            </div>
                            <div class="subsc-header__desc">
                                このプランには、オプションのプランもご用意しております。
                            </div>
                        </h2>

                        <div class="subsc-plus">
                            <div class="subsc-plus__item">
                                <h3>メンテプラス<span class="color-y">＋</span></h3>
                                <hr>
                                <h4>リース代</h4>
                                <div class="subsc-plus__item__price">＋月々<span class="price color-y">2,200</span><span class="color-y">円(税込)</span></div>
                                <h4 class="subsc-plus__item__desc">安心の<span class="color-y">メンテナンス</span>をプラス</h4>
                                <div class="subsc-plus__item__box">メンテナンス内容<br><small>(回数は最大値)</small></div>
                                <ul>
                                    <li>・法定12ヶ月点検（4回）</li>
                                    <li>・6ヶ月点検</li>
                                    <li>・無料洗車（+11回）</li>
                                    <li>・オイルエレメント交換（6回）</li>
                                    <li>・バッテリー交換（2回）</li>
                                    <li>・ワイパーゴム交換（6回）</li>
                                    <li>・ブレーキオイル交換（2回）</li>
                                    <li>・ブレーキパッド交換（1回）</li>
                                    <li>・クーラント交換（2回）</li>
                                    <li>・点火プラグ交換（1回）</li>
                                    <li>・ファンベルト交換（1回）</li>
                                    <li>・クーラーベルト交換（1回）</li>
                                </ul>
                            </div>

                            <div class="subsc-plus__item">
                                <h3>オプションプラス<span class="color-y">＋</span></h3>
                                <hr>
                                <h4>リース代</h4>
                                <div class="subsc-plus__item__price">＋月々<span class="price color-y">2,750</span><span class="color-y">円(税込)</span>
                                </div>
                                <h4 class="subsc-plus__item__desc">豪華<span class="color-y">オプションセット</span>をプラス</h4>
                                <div class="subsc-plus__item__box">オプションセット内容</div>
                                <ul>
                                    <li>・メモリー地デジナビ</li>
                                    <li>・国産タイヤ 4本</li>
                                    <li>・ドライブレコーダー</li>
                                    <li>・ボディーコーディング</li>
                                    <li>・ETC</li>
                                </ul>
                            </div>

                            <div class="subsc-plus__item">
                                <h3>プレミアムプラス<span class="color-y">＋</span></h3>
                                <hr>
                                <h4>リース代</h4>
                                <div class="subsc-plus__item__price">＋月々<span class="price color-y">4,950</span><span class="color-y">円(税込)</span>
                                </div>
                                <h4 class="subsc-plus__item__desc"><span class="color-y">メンテプラス</span>と<span class="color-y">オプションプラス</span><br><span class="color-y">両方コミコミ</span>になります</h4>
                                <div class="text-center">さらに！</div>
                                <div class="subsc-plus__item__box">キズの凹み<br>3万円保証</div>
                                <div class="text-center">＋</div>
                                <div class="subsc-plus__item__box">ガソリン<br>満タン納車</div>
                            </div>
                        </div>
                    </section>

                    <section class="subsc-section">
                        <div class="subsc-section__border">
                            <img src="/assets/img/border-parts.png">
                        </div>
                        <h2 class="subsc-header">
                            <img src="/assets/img/subsc-03.png">
                            <div class="subsc-header__labels">
                                <div>リース終了後も</div>
                                <div>選べる4つのプラン</div>
                            </div>
                            <div class="subsc-header__desc">
                                リース期間満了後は4つのプランから選択することができます。
                            </div>
                        </h2>

                        <div class="subsc-circle">
                            <div class="subsc-circle__item">
                                <h3>新しい車に<br>乗り換える</h3>
                                <hr class="circle-border">
                                <p class="subsc-circle__item__desc">
                                    次の新しい車に<br>お乗り換え
                                    <br> いただけます
                                </p>
                            </div>

                            <div class="subsc-circle__item">
                                <h3>同じ車に<br>乗り続ける</h3>
                                <hr class="circle-border">
                                <p class="subsc-circle__item__desc">
                                    再リースで引き続き<br>お乗りいただけます
                                </p>
                            </div>

                            <div class="subsc-circle__item">
                                <h3>車を<br>返却する</h3>
                                <hr class="circle-border">
                                <p class="subsc-circle__item__desc">
                                    リース満了時に<br>返却可能です
                                </p>
                            </div>

                            <div class="subsc-circle__item">
                                <h3>車を<br>現金で買い取る</h3>
                                <hr class="circle-border">
                                <p class="subsc-circle__item__desc">
                                    車を買い取ることが<br>可能です
                                </p>
                            </div>
                        </div>
                    </section>

                    <section class="subsc-section qa">
                        <h2>よくあるご質問</h2>
                        <div class="qa__border"></div>

                        <div class="qa__list">
                            <div class="qa__list-q">
                                <span>Q1</span> グレード・色は選べますか？
                            </div>
                            <div class="qa__list-a">
                                <span>A</span> 選べます。
                                <br>ただし、別途差額分だけご負担願いします。
                            </div>
                        </div>

                        <div class="qa__list">
                            <div class="qa__list-q">
                                <span>Q2</span> オプションはつけられますか？
                            </div>
                            <div class="qa__list-a">
                                <span>A</span> お好きなオプションをつけることが可能です。
                            </div>
                        </div>

                        <div class="qa__list">
                            <div class="qa__list-q">
                                <span>Q3</span> 頭金は入れられますか？
                            </div>
                            <div class="qa__list-a">
                                <span>A</span> 可能です。
                                <br>頭金(預かりリース料)があれば月々のお支払いもさらに少なくなります。
                            </div>
                        </div>

                        <div class="qa__list">
                            <div class="qa__list-q">
                                <span>Q4</span> 途中で事故をしたらどうなりますか？
                            </div>
                            <div class="qa__list-a">
                                <span>A</span> 修理できる事故は修理後、乗り続けることが可能です。
                                <br> ただし修理費用などはお客様負担となり、もしも廃車にする場合は残金を一括精算していただきます。
                                <br> こうなった時のためにも任意保険・車両保険に加入しましょう。
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>

        <section class="subsc">
            <div class="subsc__title">
                <p class="title">RENTA CAR</p>
                <p class="sub-title">オダギリオートのレンタカー</p>
            </div>

            <div class="subsc__wrap">
                <div class="subsc__wrap--box">
                    <div class="subsc__wrap--inner">
                        <ul class="subsc__wrap--inner--list">
                            <li class="subsc__wrap--inner--list--item">
                                <div class="subsc__wrap--inner--list--item--contents">
                                    <h2 class="subsc__wrap--inner--list--item--contents--title">
                                        オダギリオートの<br>レンタカー
                                    </h2>
                                    <p class="subsc__wrap--inner--list--item--contents--text">
                                        オダギリオートではレンタカーのサービスもございます。
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <section class="subsc-section qa">
                        <h2>ご利用料金</h2>
                        <div class="qa__border"></div>
                    </section>

                    <section class="subsc-section rentacar">
                        <table class="rentacar-table">
                            <thead>
                                <tr>
                                    <th>車種</th>
                                    <th>6時間まで</th>
                                    <th>24時間まで</th>
                                    <th>以降1日ごと<br>24時間ごと</th>
                                    <th>超過料金<br>1時間ごと</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="rentacar-table__name">マツダ AZワゴン</td>
                                    <td class="td">3,900円</td>
                                    <td class="td">5,500円</td>
                                    <td class="td">4,700円</td>
                                    <td class="td">1,000円</td>
                                </tr>
                                <tr>
                                    <td class="rentacar-table__name">スズキ アルト</td>
                                    <td class="td">3,900円</td>
                                    <td class="td">5,500円</td>
                                    <td class="td">4,700円</td>
                                    <td class="td">1,000円</td>
                                </tr>
                                <tr>
                                    <td class="rentacar-table__name">トヨタ ヴォクシー</td>
                                    <td class="td">12,600円</td>
                                    <td class="td">18,000円</td>
                                    <td class="td">15,300円</td>
                                    <td class="td">2,000円</td>
                                </tr>
                                <tr>
                                    <td class="rentacar-table__name">マツダ ビアンテ</td>
                                    <td class="td">12,600円</td>
                                    <td class="td">18,000円</td>
                                    <td class="td">15,300円</td>
                                    <td class="td">2,000円</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="rentacar-description">
                            <h3>料金のお支払い</h3>
                            <div>
                                ・基本料金はご出発時に、延長料金・ガソリン料金などはご返却時にご精算させていただきます。<br>
                                ・お支払いは現金又はクレジットカードでお願い致します。
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </section>

        <app-top-contact></app-top-contact>
        <footer class="footer">
            <p class="footer__copyright">&copy; 2021 ODAGIRI AUTO, INC.</p>
        </footer>
        <video class="video" [muted]="'muted'" autoplay loop playsinline>
            <source src="/assets/img/backgroud_movie.mp4" type="video/mp4" />
        </video>
    </div>
    <div class="bodyWrap__back--left"></div>
    <div class="bodyWrap__back--right"></div>
</div>
<script>
    var timer = 0;
    var currentWidth = window.innerWidth;
    $(window).resize(function() {
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }

        timer = setTimeout(function() {
            location.reload();
        }, 200);
    });
</script>
<script>
    var state = false;
    var scrollpos;
    $(".toggle").on("click", function() {
        $(".toggle").toggleClass("active");
        $(".header__navwrap--sidenav").slideToggle();
    });
</script>
<script>
    var $scrollInt = $("#scroll").offset().top;

    const myFunc = function() {
        var elem = document.getElementById("scroll");
        var wh = window.innerHeight;
        elem.style.height = wh + "px";
    };

    window.addEventListener("load", myFunc);
    window.addEventListener("resize", myFunc);
</script>
<script>
    (function($) {
        $(".scroll-area").mCustomScrollbar();
    })(jQuery);
</script>
<script>
    if (
        navigator.userAgent.match(/MSIE 10/i) ||
        navigator.userAgent.match(/Trident\/7\./) ||
        navigator.userAgent.match(/Edge\/12\./)
    ) {
        $("html").on("mousewheel", function() {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
</script>