<div class="bodyWrap">
    <div class="videoWrap">
        <header class="header">
            <div class="header__navwrap">
                <div class="header__navwrap--topnav">
                    <p class="header__navwrap--topnav--text">
                        新車・中古車・整備・修理
                    </p>
                    <button class="toggle button-all" tabindex="1">
                        <div class="toggle__wrap">
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                        </div>
                    </button>
                </div>
                <nav class="header__navwrap--sidenav">
                    <ul class="header__navwrap--sidenav--list">
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/" title="ホーム" tabindex="2" ontouchstart="">ホーム</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/subscription" title="サブスク" tabindex="2">サブスク</a > </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/about"
                                title="代表挨拶"
                                tabindex="3"
                                ontouchstart=""
                                >代表挨拶</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/service"
                                title="事業内容"
                                tabindex="4"
                                ontouchstart=""
                                >事業内容</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/news"
                                title="お知らせ・イベント情報"
                                tabindex="5"
                                ontouchstart=""
                                >お知らせ・イベント情報</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/car-lineup"
                                title="在庫一覧"
                                tabindex="6"
                                ontouchstart=""
                                >在庫一覧</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/company-info"
                                title="店舗情報"
                                tabindex="7"
                                ontouchstart=""
                                >店舗情報</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/contact"
                                title="お問い合わせ"
                                tabindex="8"
                                ontouchstart=""
                                >お問い合わせ</a
                            >
                        </li>
                        <li
                            class="header__navwrap--sidenav--list--banner instagram__banner"
                        >
                            <a
                                href="https://www.instagram.com/odagiriauto/"
                                target="_blank"
                                rel="”noopener”"
                                title="ODAGIRI AUTO instagram"
                                tabindex="10"
                                ontouchstart=""
                            >
                                <img
                                    src="/assets/img/instagram.svg"
                                    alt="ODAGIRI AUTO instagramアカウント"
                                />
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <section class="contact">
            <div class="contact__title">
                <p class="title">CONTACT</p>
                <p class="sub-title">お問い合わせ</p>
            </div>
            <div class="contact__form">
                <p class="contact-phone">
                    お電話でのお問い合わせはこちらまで。<br>
                    <a href="tel:0173-46-2598" title="0173-46-2598">
                        TEL: 0173-46-2598
                    </a>
                </p>
                <a class="topContact__wrap--grid--call--box" href="tel:0173-46-2598" ontouchstart="">
                    <div class="topContact__wrap--grid--call--box--flex">
                        <div class="topContact__wrap--grid--call--box--flex--img">
                            <img src="/assets/img/mobile.png" alt="電話でお問い合わせ" />
                        </div>
                        <span class="topContact__wrap--grid--call--box--flex--text">お電話でのお問い合わせ</span >
                    </div>
                </a>
            </div>

            <div class="contact__form">
                <form id="contact__form--inner" [formGroup]="formGroup">
                    <div class="contact__form--inner--group">
                        <label
                            for="name"
                            class="contact__form--inner--group--item"
                            >お名前<span class="required">（必須）</span>
                        <span class="error" *ngIf="
                                    name.hasError('required') &&
                                    (name.dirty || name.touched)
                                ">必須項目です</span
                            >
                        </label>
                        <input
                            type="text"
                            class="contact__form--inner--group--text"
                            id="name"
                            name="name"
                            placeholder="お名前"
                            tabindex="11"
                            ontouchstart=""
                            formControlName="name"
                        />
                    </div>
                    <div class="contact__form--inner--group">
                        <label
                            for="company"
                            class="contact__form--inner--group--item"
                            >会社名
                        </label>
                        <input
                            type="text"
                            class="contact__form--inner--group--text"
                            id="company"
                            name="company"
                            placeholder="会社名"
                            tabindex="11"
                            ontouchstart=""
                            formControlName="company"
                        />
                    </div>
                    <div class="contact__form--inner--group">
                        <label
                            for="email"
                            class="contact__form--inner--group--item"
                            >Email<span class="required">（必須）</span>
                        <span class="error" *ngIf="
                                    email.hasError('required') &&
                                    (email.dirty || email.touched)
                                ">必須項目です</span
                            >
                        </label>
                        <input
                            type="text"
                            class="contact__form--inner--group--text"
                            id="email"
                            name="email"
                            placeholder="Emailアドレス"
                            tabindex="12"
                            ontouchstart=""
                            formControlName="email"
                        />
                    </div>
                    <div class="contact__form--inner--group">
                        <label
                            for="emaill_check"
                            class="contact__form--inner--group--item"
                            >Email（確認用）<span class="required"
                                >（必須）</span
                            >
                            <span
                                class="error"
                                *ngIf="
                                    email_check.hasError('required') &&
                                    (email_check.dirty || email_check.touched)
                                "
                                >必須項目です</span
                            >
                        </label>
                        <input
                            type="text"
                            class="contact__form--inner--group--text"
                            id="email_check"
                            name="email_check"
                            placeholder="※確認のためもう一度ご入力ください。"
                            tabindex="13"
                            ontouchstart=""
                            formControlName="email_check"
                        />
                    </div>
                    <div class="contact__form--inner--group">
                        <label
                            for="phone"
                            class="contact__form--inner--group--item"
                            >お電話番号
                            <span
                                class="error"
                                *ngIf="
                                    phone.hasError('required') &&
                                    (phone.dirty || phone.touched)
                                "
                                >必須項目です</span
                            >
                        </label>
                        <input
                            type="text"
                            class="contact__form--inner--group--text"
                            id="phone"
                            name="phone"
                            placeholder="お電話番号 ※半角英数字でご入力ください"
                            tabindex="14"
                            ontouchstart=""
                            formControlName="phone"
                        />
                    </div>
                    <div class="contact__form--inner--group contact-textarea">
                        <div class="contact__form--inner--group--content">
                            <label
                                for="body"
                                class="contact__form--inner--group--content--item"
                                >お問い合わせ内容<span class="required"
                                    >（必須）</span
                                >
                                <span
                                    class="error"
                                    *ngIf="
                                        body.hasError('required') &&
                                        (body.dirty || body.touched)
                                    "
                                    >必須項目です</span
                                >
                            </label>
                            <span
                                id="count"
                                class="contact__form--inner--group--content--item--count"
                            ></span>
                    </div>
                    <textarea class="contact__form--inner--group--textarea" id="body" name="body" placeholder="お問い合わせ内容（1000文字まで）" rows="3" tabindex="15" ontouchstart="" formControlName="body"></textarea>
            </div>
            <button type="submit" class="contact__form--inner--btn" tabindex="16" ontouchstart="" (click)="postContact('confirm')" [disabled]="!formGroup.valid || sended">
                        送信
                    </button>
            <div class="message">{{ message }}</div>
            </form>
    </div>
    </section>
    <footer class="footer">
        <p class="footer__copyright">&copy; 2021 ODAGIRI AUTO, INC.</p>
    </footer>
    <video class="video" [muted]="'muted'" autoplay loop playsinline>
            <source src="/assets/img/backgroud_movie.mp4" type="video/mp4" />
        </video>
</div>
<div class="bodyWrap__back--left"></div>
<div class="bodyWrap__back--right"></div>
</div>
<script>
    var timer = 0;
    var currentWidth = window.innerWidth;
    $(window).resize(function() {
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }

        timer = setTimeout(function() {
            location.reload();
        }, 200);
    });
</script>
<script>
    var state = false;
    var scrollpos;
    $(".toggle").on("click", function() {
        $(".toggle").toggleClass("active");
        $(".header__navwrap--sidenav").slideToggle();
    });
</script>
<script>
    var $scrollInt = $("#scroll").offset().top;

    const myFunc = function() {
        var elem = document.getElementById("scroll");
        var wh = window.innerHeight;
        elem.style.height = wh + "px";
    };

    window.addEventListener("load", myFunc);
    window.addEventListener("resize", myFunc);
</script>
<script>
    (function($) {
        $(".scroll-area").mCustomScrollbar();
    })(jQuery);
</script>
<script>
    if (
        navigator.userAgent.match(/MSIE 10/i) ||
        navigator.userAgent.match(/Trident\/7\./) ||
        navigator.userAgent.match(/Edge\/12\./)
    ) {
        $("html").on("mousewheel", function() {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
</script>