import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/rx';
import { SharedService } from './services/shared.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor( private shared: SharedService ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log(req.url);

    // authorization request.
    if (req.headers.get('Authorization') !== null) {
      return next.handle(req);
    }

    // set access token.
    let token = '';
    if (this.shared.authentication !== null) {
      token = this.shared.authentication.token;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return next.handle(req.clone({headers}));
  }
}
