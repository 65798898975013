import { Injectable } from '@angular/core';
import { Information } from '../models/information';
import { SharedService } from '../services/shared.service';

import { API } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class InformationService {

  constructor( private shared: SharedService ) { }

  getInformations(division, limitFlag, createdat): Promise<Information[]> {
    return new Promise<Information[]>(async (resolve, reject) => {
      let limit = 0;
      if (limitFlag) {
        limit = limitFlag;
      }

      const params = {
        limit,
        createdat
      };

      try {
        this.shared.loading = true;
        const informations = await API.get('odagiriautoapi', `/informations/${division}`, { queryStringParameters: params });

        resolve(informations);
      } catch (error) {
        reject(error);
      } finally {
        this.shared.loading = false;
      }
    });
  }

  getInformation(id): Promise<Information> {
    return new Promise<Information>(async (resolve, reject) => {
      try {
        this.shared.loading = true;
        const response = await API.get('odagiriautoapi', `/informations/object/${id}`, {});

        resolve(response);
      } catch (error) {
        reject(error);
      } finally {
        this.shared.loading = false;
      }
    });
  }

  createInformation(information): Promise<Information> {
    return new Promise<Information>(async (resolve, reject) => {
      try {
        this.shared.loading = true;
        const response = await API.post('odagiriautoapi', `/informations`, { body: information });

        resolve(response);
      } catch (error) {
        reject(error);
      } finally {
        this.shared.loading = false;
      }
    });
  }

  updateInformation(information): Promise<Information> {
    return new Promise<Information>(async (resolve, reject) => {
      try {
        this.shared.loading = true;
        const response = await API.put('odagiriautoapi', `/informations`, { body: information });

        resolve(response);
      } catch (error) {
        reject(error);
      } finally {
        this.shared.loading = false;
      }
    });
  }

  deleteInformation(id): Promise<Information> {
    return new Promise<Information>(async (resolve, reject) => {
      try {
        this.shared.loading = true;
        const information = await API.del('odagiriautoapi', `/informations/object/${id}`, {});

        resolve(information);
      } catch (error) {
        reject(error);
      } finally {
        this.shared.loading = false;
      }
    });
  }
}
