import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlMatcher, UrlSegment } from '@angular/router';

import { IndexComponent } from './components/index/index.component';
import { AboutComponent } from './components/about/about.component';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { ContactComponent } from './components/contact/contact.component';
import { NewsComponent } from './components/news/news.component';
import { NewsShowComponent } from './components/news-show/news-show.component';
import { CarLineupComponent } from './components/car-lineup/car-lineup.component';
import { CarShowComponent } from './components/car-show/car-show.component';
import { CarIndexComponent } from './components/car-index/car-index.component';
import { ServiceComponent } from './components/service/service.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';

export function categoryConstraint(url: UrlSegment[]) {

  if (url.length === 0) {
    return ({
      consumed: url,
      posParams: {
        category: new UrlSegment('', {})
      }
    });
  }

  console.log('categoryConstraint', url[0].path);

  if (url[0].path === 'news') {
    return ({
      consumed: url,
      posParams: {
        category: new UrlSegment(url[0].path, {})
      }
    });
  } else if (url[0].path === 'event') {
    return ({
      consumed: url,
      posParams: {
        category: new UrlSegment(url[0].path, {})
      }
    });
  } else if (url[0].path === 'other') {
    return ({
      consumed: url,
      posParams: {
        category: new UrlSegment(url[0].path, {})
      }
    });
  } else if (url[0].path === 'stock') {
    return ({
      consumed: url,
      posParams: {
        category: new UrlSegment(url[0].path, {})
      }
    });
  } else if (url[0].path === '') {
    return ({
      consumed: url,
      posParams: {
        category: new UrlSegment(url[0].path, {})
      }
    });
  }

  return null;
}

const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'car',
    component: CarIndexComponent
  },
  {
    path: 'car-lineup',
    component: CarLineupComponent
  },
  {
    path: 'car-lineup/:id',
    component: CarShowComponent
  },
  {
    path: 'company-info',
    component: CompanyInfoComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'contact/:id',
    component: ContactComponent
  },
  {
    path: 'news',
    children: [
      {
        matcher: categoryConstraint,
        component: NewsComponent
      },
      {
        path: ':id',
        component: NewsShowComponent
      },
    ]
  },
  {
    path: 'service',
    component: ServiceComponent
  },
  {
    path: 'subscription',
    component: SubscriptionComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
