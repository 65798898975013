import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { InformationService } from '../../services/information.service';
import { Contact } from '../../models/contact';
import { Information } from 'src/app/models/information';

declare var $;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  information: Information = { childs: [] } as Information;

  sended = false;
  message = '';

  formGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
    ]),
    company: new FormControl('', [
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    email_check: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    phone: new FormControl('', [
      Validators.required,
    ]),
    type: new FormControl('0', [
      Validators.required,
    ]),
    body: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(
    private contactService: ContactService,
    private route: ActivatedRoute,
    private informationService: InformationService
  ) { }

  async ngOnInit() {
    $(".toggle").on("click", function () {
      console.log("toggle");
      $(".toggle").toggleClass("active");
      $(".videoWrap").toggleClass("open");
      $(".header__navwrap--sidenav").slideToggle();
    });

    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      console.log('id', id);
      this.information = await this.informationService.getInformation(id);

      const message = `
${this.information.brand} ${this.information.title}へのお問い合わせ
${window.location.protocol}//${window.location.hostname}/car-lineup/${id}
      `;
      this.formGroup.patchValue({ body: message });
    }
  }

  public get name() {
    return this.formGroup.get('name');
  }

  public get company() {
    return this.formGroup.get('company');
  }

  public get email() {
    return this.formGroup.get('email');
  }

  public get email_check() {
    return this.formGroup.get('email_check');
  }

  public get phone() {
    return this.formGroup.get('phone');
  }

  public get type() {
    return this.formGroup.get('type');
  }

  public get body() {
    return this.formGroup.get('body');
  }

  async postContact(target: string) {
    if (!confirm('送信しますか？')) {
      return false;
    }
    const contact: Contact = this.formGroup.value;
    try {
      this.message = '送信しています';
      const response = await this.contactService.postContact(contact);
      this.sended = true;
      this.message = '送信完了しました';
    } catch (e) {
      this.message = '送信に失敗しました。また後ほどお試しください';
      this.sended = false;
      console.log(e);
    }
  }
}
