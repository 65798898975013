import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(".toggle").on("click", function () {
      $(".toggle").toggleClass("active");
      $(".videoWrap").toggleClass("open");
      $(".header__navwrap--sidenav").slideToggle();
    });
  }
}
