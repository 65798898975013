import { Component } from '@angular/core';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = '五所川原市　つがる市　株式会社オダギリオート';

  constructor(public sharedService: SharedService) {
    window.addEventListener("load", this.myFunc);
    window.addEventListener("resize", this.myFunc);
  }

  myFunc = () => {
    const elem = document.getElementById("scroll");
    const wh = window.innerHeight;
    elem.style.height = wh + "px";
  };

  onActivate(event) {
    window.scroll(0,0);
  }
}
