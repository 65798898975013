import { Component, OnInit, AfterContentInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { InformationService } from '../../services/information.service';

import * as moment from 'moment';
import { Information, setSignedUrl } from 'src/app/models/information';

declare var $;
declare var Swiper;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, AfterContentInit {

  dates = Array();
  currentCategory = 'news_top';
  informations: Information[];

  constructor(
    private informationService: InformationService,
    private route: ActivatedRoute) {
    this.dates.push(new Date());

    for (let i = 0; i < 11; i++) {
      this.dates.push(moment().add(-1 - i, 'month'));
    }
  }

  async ngOnInit() {
    $(".toggle").on("click", function () {
      console.log("toggle");
      $(".toggle").toggleClass("active");
      $(".videoWrap").toggleClass("open");
      $(".header__navwrap--sidenav").slideToggle();
    });

    this.currentCategory = this.route.snapshot.paramMap.get('category') || 'all';
    console.log('category', this.currentCategory);

    this.informations = await this.informationService.getInformations(this.currentCategory, false, moment().format('YYYY/MM/01'));
    for (let info of this.informations) {
      const urls = await setSignedUrl(info);
      for (let i in urls) {
        info.childs[i].signedUrls.push(urls[i]);
      }
    }
  }

  ngAfterContentInit() {
    var swiper = new Swiper(".swiper-container", {
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });
  }

  async onClickDate(d) {
    window.scroll(0, 0);
    this.informations = await this.informationService.getInformations(this.currentCategory, false, moment(d).format('YYYY/MM/01'));
    for (let info of this.informations) {
      const urls = await setSignedUrl(info);
      for (let i in urls) {
        info.childs[i].signedUrls.push(urls[i]);
      }
    }
  }
}
