import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LightboxModule } from 'ngx-lightbox';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { InformationService } from './services/information.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Interceptor } from './interceptor';

import { SharedModule } from './shared/shared.module';
import { IndexComponent } from './components/index/index.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutComponent } from './components/about/about.component';
import { CarLineupComponent } from './components/car-lineup/car-lineup.component';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { ContactComponent } from './components/contact/contact.component';
import { NewsComponent } from './components/news/news.component';
import { ServiceComponent } from './components/service/service.component';
import { CategoryPipe } from './category.pipe';
import { CategoryComponent } from './components/category/category.component';
import { NewsShowComponent } from './components/news-show/news-show.component';
import { CarShowComponent } from './components/car-show/car-show.component';
import { CarIndexComponent } from './components/car-index/car-index.component';
import { TopContactComponent } from './components/top-contact/top-contact.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    CarLineupComponent,
    CompanyInfoComponent,
    ContactComponent,
    NewsComponent,
    ServiceComponent,
    CategoryPipe,
    CategoryComponent,
    NewsShowComponent,
    CarShowComponent,
    CarIndexComponent,
    TopContactComponent,
    LoadingComponent,
    SubscriptionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    NgScrollbarModule,
    LightboxModule,
    NgbModule 
  ],
  providers: [
    InformationService,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
