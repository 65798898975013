<section class="topContact" [class.index]="topFlag">
    <div class="topContact__wrap">
        <h1 class="topContact__wrap--head">
            <p class="topContact__wrap--head--title title">CONTACT</p>
            <p class="topContact__wrap--head--sub sub-title">
                お問い合わせ
            </p>
        </h1>
        <div class="topContact__wrap--grid">
            <div class="topContact__wrap--grid--call">
                <a
                    class="topContact__wrap--grid--call--box"
                    href="tel:0173-46-2598"
                    ontouchstart=""
                >
                    <div
                        class="topContact__wrap--grid--call--box--flex"
                    >
                        <div
                            class="topContact__wrap--grid--call--box--flex--img"
                        >
                            <img
                                src="/assets/img/mobile.png"
                                alt="電話でお問い合わせ"
                            />
                        </div>
                        <span
                            class="topContact__wrap--grid--call--box--flex--text"
                            >お電話でのお問い合わせ</span
                        >
                    </div>
                </a>
            </div>
            <div class="topContact__wrap--grid--mail">
                <a
                    class="topContact__wrap--grid--mail--box"
                    href="/contact"
                    ontouchstart=""
                >
                    <div
                        class="topContact__wrap--grid--mail--box--flex"
                    >
                        <div
                            class="topContact__wrap--grid--mail--box--flex--img"
                        >
                            <img
                                src="/assets/img/mail.png"
                                alt="メールでお問い合わせ"
                            />
                        </div>
                        <span
                            class="topContact__wrap--grid--mail--box--flex--text"
                            >フォームからお問い合わせ</span
                        >
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>