<div class="bodyWrap">
    <div class="videoWrap">
        <header class="header">
            <div class="header__navwrap">
                <div class="header__navwrap--topnav">
                    <p class="header__navwrap--topnav--text">
                        新車・中古車・整備・修理
                    </p>
                    <button class="toggle button-all" tabindex="1">
                        <div class="toggle__wrap">
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                        </div>
                    </button>
                </div>
                <nav class="header__navwrap--sidenav">
                    <ul class="header__navwrap--sidenav--list">
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/" title="ホーム" tabindex="2" ontouchstart="">ホーム</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/subscription" title="サブスク" tabindex="2">サブスク</a > </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/about" title="代表挨拶" tabindex="3" ontouchstart="">代表挨拶</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/service" title="事業内容" tabindex="4" ontouchstart="">事業内容</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/news" title="お知らせ・イベント情報" tabindex="5" ontouchstart="">お知らせ・イベント情報</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/car-lineup" title="在庫一覧" tabindex="6" ontouchstart="">在庫一覧</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/company-info" title="店舗情報" tabindex="7" ontouchstart="">店舗情報</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/contact" title="お問い合わせ" tabindex="8" ontouchstart="">お問い合わせ</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--banner
                            instagram__banner">
                            <a href="https://www.instagram.com/odagiriauto/" target="_blank" rel="”noopener”" title="ODAGIRI AUTO instagram" tabindex="10" ontouchstart="">
                                <img src="/assets/img/instagram.svg" alt="ODAGIRI AUTO instagramアカウント" />
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <section class="lineUp">
            <div class="lineUp__title">
                <p class="title">CAR LINEUP</p>
                <p class="sub-title">在庫一覧</p>
            </div>
            <ul class="lineUp__list">
                <li class="lineUp__list--item">
                    <div class="lineUp__list--item-img" *ngIf="information.childs.length> 0">
                        <img class="lineUp__list--item-img__img" src="{{
                            information.childs[0].signedUrls[0] }}" alt="{{
                            information.title }}" />
                    </div>
                    <div class="lineUp__list--item--info">
                        <p class="lineUp__list--item--info--brand">
                            {{ information.brand }}
                        </p>
                        <p class="lineUp__list--item--info--name">
                            {{ information.title }}
                        </p>
                        <div class="lineUp__list--item--info--conditions">
                            <span class="lineUp__list--item--info--conditions--text">{{
                                information.carType }}</span>
                            <span class="lineUp__list--item--info--conditions--text">{{
                                information.mission }}</span>
                            <span class="lineUp__list--item--info--conditions--text">{{
                                information.color }}
                            </span>
                            <span class="lineUp__list--item--info--conditions--text">{{
                                information.warranty }}</span>
                        </div>
                    </div>
                    <div class="lineUp__list--item--grade">
                        <div class="lineUp__list--item--grade--box">
                            <p class="lineUp__list--item--grade--box--title">
                                年式
                            </p>
                            <p class="lineUp__list--item--grade--box--text">
                                {{ information.modelYear }}
                            </p>
                        </div>
                        <div class="lineUp__list--item--grade--box">
                            <p class="lineUp__list--item--grade--box--title">
                                走行距離
                            </p>
                            <p class="lineUp__list--item--grade--box--text">
                                {{ information.mileage }}
                            </p>
                        </div>
                        <div class="lineUp__list--item--grade--box">
                            <p class="lineUp__list--item--grade--box--title">
                                排気量
                            </p>
                            <p class="lineUp__list--item--grade--box--text">
                                {{ information.displacement }}
                            </p>
                        </div>
                        <div class="lineUp__list--item--grade--box">
                            <p class="lineUp__list--item--grade--box--title">
                                車検有無
                            </p>
                            <p class="lineUp__list--item--grade--box--text">
                                車検整備なし {{ information.inspection }}
                            </p>
                        </div>
                        <div class="lineUp__list--item--grade--box">
                            <p class="lineUp__list--item--grade--box--title">
                                修復歴
                            </p>
                            <p class="lineUp__list--item--grade--box--text">
                                {{ information.repair }}
                            </p>
                        </div>
                    </div>
                    <p class="lineUp__list--item--price">
                        <span class="lineUp__list--item--price--text">本体価格</span>
                        <span class="lineUp__list--item--price--number">{{
                            information.price
                            }}</span>
                        <span class="lineUp__list--item--price--en">万円</span>
                    </p>
                    <p class="lineUp__list--item--price price-tax">
                        <span class="lineUp__list--item--price--text">支払総額(税込）</span>
                        <span class="lineUp__list--item--price--number">{{
                            information.priceTotal
                            }}</span>
                        <span class="lineUp__list--item--price--en">万円</span>
                    </p>

                    <p class="lineUp__list--item--description">
                        {{ information.description }}
                    </p>

                    <div class="flex-container">
                        <div class="flex-container__item" *ngFor="let c of
                            information.childs; let i=index" (click)="onClickThumb(i)">
                            <img class="flex-container__item__img" src="{{
                                c.signedUrls[0] }}" alt="{{ information.title }}" />
                        </div>
                    </div>

                    <div class="lineUp__list--item--contact">
                        <a class="lineUp__list--item--contact--btn" href="/contact/{{ information.id }}" ontouchstart="" tabindex="11">
                            CONTACT
                        </a>
                        <a class="lineUp__list--item--contact--btn" href="javascript:history.back()" title="戻る" tabindex="16" ontouchstart="">BACK</a>
                    </div>
                </li>
            </ul>
        </section>
        <app-top-contact></app-top-contact>
        <footer class="footer">
            <p class="footer__copyright">&copy; 2021 ODAGIRI AUTO, INC.</p>
        </footer>
        <video class="video" [muted]="'muted'" autoplay loop playsinline>
            <source src="/assets/img/backgroud_movie.mp4" type="video/mp4" />
        </video>
    </div>
    <div class="bodyWrap__back--left"></div>
    <div class="bodyWrap__back--right"></div>
</div>
<script>
    var timer = 0;
    var currentWidth = window.innerWidth;
    $(window).resize(function() {
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }

        timer = setTimeout(function() {
            location.reload();
        }, 200);
    });
</script>
<script>
    var state = false;
    var scrollpos;
    $(".toggle").on("click", function() {
        $(".toggle").toggleClass("active");
        $(".header__navwrap--sidenav").slideToggle();
    });
</script>
<script>
    var $scrollInt = $("#scroll").offset().top;

    const myFunc = function() {
        var elem = document.getElementById("scroll");
        var wh = window.innerHeight;
        elem.style.height = wh + "px";
    };

    window.addEventListener("load", myFunc);
    window.addEventListener("resize", myFunc);
</script>
<script>
    (function($) {
        $(".scroll-area").mCustomScrollbar();
    })(jQuery);
</script>
<script>
    if (
        navigator.userAgent.match(/MSIE 10/i) ||
        navigator.userAgent.match(/Trident\/7\./) ||
        navigator.userAgent.match(/Edge\/12\./)
    ) {
        $("html").on("mousewheel", function() {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
</script>
<app-loading></app-loading>