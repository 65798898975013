import { UrlSegment } from '@angular/router';
import { Storage } from 'aws-amplify';
import { SpawnSyncOptionsWithStringEncoding } from 'child_process';
import * as moment from 'moment';

export interface InformationChild {
  id: string;
  description: string;
  uploadFiles: string[];
  signedUrls: string[];
}

export interface Information {
  // id
  id: string;
  // ハッチバック
  carType: string;
  // トヨタ
  brand: string;
  // コラム4AT
  mission: string;
  // 色
  color: string;
  // 保証
  warranty: string;
  // 本体価格
  price: string;
  // 税込み価格
  priceTotal: string;
  // 車種
  title: string;
  // 説明
  description: string;
  // 年式
  modelYear: string;
  // 走行距離
  mileage: string;
  // 排気量
  displacement: string;
  // 車検
  inspection: string;
  // 修復歴
  repair: string;
  all: string;
  division: string;
  createdat: string;
  updatedat: string;
  uploadFiles: string[];
  childs: InformationChild[];
  childsJson: string;

  // constructor() {
  //     this.id = '';
  //     this.title = '';
  //     this.description = '';
  //     this.all = '';
  //     this.division = '';
  //     this.createdat = '';
  //     this.updatedat = '';
  //     this.uploadFiles = [];
  // }
}

export async function setSignedUrl(information: Information): Promise<Array<any>> {
  const urls = [];

  for (let i in information.childs) {
    const child = information.childs[i];
    information.childs[i].signedUrls = [];

    if (information.childs[i].uploadFiles.length > 0 && information.childs[i].signedUrls.length === 0) {

      const parts = information.childs[i].uploadFiles[0].split('/');
      const resizeKey = `${parts[0]}/resized-${parts[1]}`;

      const url = await Storage.get(resizeKey, {
        level: 'public'
      });

      // information.childs[i].signedUrls.push(url as string);
      urls.push(url);
    }
  }

  return urls;
}

export function createChild(): InformationChild {
  return {
    id: moment().format('YYYYMMDDhhmmss'),
    description: '',
    uploadFiles: [],
    signedUrls: [],
  }
}
