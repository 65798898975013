import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(".toggle").on("click", function () {
      console.log("toggle");
      $(".toggle").toggleClass("active");
      $(".videoWrap").toggleClass("open");
      $(".header__navwrap--sidenav").slideToggle();
    });

    // $(".scroll-area").mCustomScrollbar();
  }

}
