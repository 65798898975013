<div class="bodyWrap">
    <div class="videoWrap">
        <header class="header">
            <div class="header__navwrap">
                <div class="header__navwrap--topnav">
                    <p class="header__navwrap--topnav--text">
                        新車・中古車・整備・修理
                    </p>
                    <button class="toggle button-all" tabindex="1">
                        <div class="toggle__wrap">
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                        </div>
                    </button>
                </div>
                <nav class="header__navwrap--sidenav">
                    <ul class="header__navwrap--sidenav--list">
                        <li class="header__navwrap--sidenav--list--item"> <a href="/" title="ホーム" tabindex="1">ホーム</a> </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/subscription" title="サブスク" tabindex="2">サブスク</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/about" title="代表挨拶" tabindex="3">代表挨拶</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/service" title="事業内容" tabindex="4" >事業内容</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/news" title="お知らせ・イベント情報" tabindex="5" >お知らせ・イベント情報</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/car-lineup" title="在庫一覧" tabindex="6" >在庫一覧</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/company-info" title="店舗情報" tabindex="7" >店舗情報</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/contact" title="お問い合わせ" tabindex="8" >お問い合わせ</a > </li>
                        <li class="header__navwrap--sidenav--list--banner instagram__banner" >
                            <a href="https://www.instagram.com/odagiriauto/" target="_blank" rel="”noopener”" title="ODAGIRI AUTO instagram" tabindex="9" >
                                <img src="/assets/img/instagram.svg" alt="ODAGIRI AUTO instagramアカウント" />
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="header__logo">
                <div class="header__logo--img">
                    <img src="/assets/img/logo.png" alt="CAR SHOP&MAINTENANCE Odagiri Auto" />
                </div>
            </div>
            <div class="header__news">
                <h1 class="header__news--title">
                    <p class="header__news--title--text title">NEWS・EVENT</p>
                    <p class="header__news--title--sub sub-title">
                        お知らせ・イベント情報
                    </p>
                </h1>
                <div class="header__news--wrap">
                    <ul class="header__news--wrap--list scroll-content">
                        <li *ngFor="let i of informations" class="header__news--wrap--list--item">
                            <a class="header__news--wrap--list--item--link" href="/news/{{ i.id }}" title="{{ i.title }}" tabindex="10" ontouchstart="">
                                <app-category [division]="i.division"></app-category>
                                <span class="header__news--wrap--list--item--link--day">
                                    {{ i.createdat | date: "yy/MM/dd E" }}
                                </span>
                                <span class="header__news--wrap--list--item--link--text">
                                    {{ i.title }}
                                </span>
                            </a>
                        </li>
                    </ul>
                    <a class="header__news--wrap--btn" href="/news" title="NEWS READ MORE" tabindex="16" ontouchstart="">READ MORE</a>
                </div>
            </div>
            <div class="header__lineup">
                <h1 class="header__lineup--title">
                    <p class="header__lineup--title--text title">CAR LINEUP</p>
                    <p class="header__lineup--title--sub sub-title">
                        最近の在庫一覧
                    </p>
                </h1>
                <div class="header__lineup--wrap">
                    <ul class="header__lineup--wrap--list">
                        <li *ngFor="let i of stocks" class="header__lineup--wrap--list--item">
                            <a class="header__lineup--wrap--list--item--link" href="/car-lineup/{{ i.id }}" title="{{ i.title }}" tabindex="10" ontouchstart="">
                                <div class="header__lineup--wrap--list--item--link__img" *ngIf="i.childs.length > 0" >
                                    <img class="header__lineup--wrap--list--item--link__img--img" src="{{ i.childs[0].signedUrls[0] }}" alt="{{ i.title }}" />
                                </div>
                                <div class="header__lineup--wrap--list--item--link--text">
                                    <div class="header__lineup--wrap--list--item--link--text--inner">
                                        <p class="header__lineup--wrap--list--item--link--text--brand">{{ i.brand }}</p>
                                        <p class="header__lineup--wrap--list--item--link--text--name">{{ i.title }}</p>
                                        <div class="header__lineup--wrap--list--item--link--text--view">
                                            <span class="header__lineup--wrap--list--item--link--text--view--line"></span>
                                            <span class="header__lineup--wrap--list--item--link--text--view--btn">VIEW</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <a class="header__lineup--wrap--btn" href="/car-lineup" title="NEWS READ MORE" tabindex="16" ontouchstart="">READ MORE</a>
                </div>
            </div>
        </header>
        <app-top-contact [topFlag]=true></app-top-contact>
        <footer class="footer">
            <p class="footer__copyright">&copy; 2021 ODAGIRI AUTO, INC.</p>
        </footer>
        <video class="video" [muted]="'muted'" autoplay loop playsinline>
            <source src="/assets/img/backgroud_movie.mp4" type="video/mp4" />
        </video>
    </div>
    <div class="bodyWrap__back--left"></div>
    <div class="bodyWrap__back--right"></div>
</div>
<script>
    var timer = 0;
    var currentWidth = window.innerWidth;
    $(window).resize(function() {
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }

        timer = setTimeout(function() {
            location.reload();
        }, 200);
    });
</script>
<script>
    var state = false;
    var scrollpos;
    $(".toggle").on("click", function() {
        $(".toggle").toggleClass("active");
        $(".header__navwrap--sidenav").slideToggle();
    });
</script>
<script>
    var $scrollInt = $("#scroll").offset().top;

    const myFunc = function() {
        var elem = document.getElementById("scroll");
        var wh = window.innerHeight;
        elem.style.height = wh + "px";
    };

    window.addEventListener("load", myFunc);
    window.addEventListener("resize", myFunc);
</script>
<script>
    (function($) {
        $(".scroll-area").mCustomScrollbar();
    })(jQuery);

    $(window).on("load", function() {
        $(".scroll-content").mCustomScrollbar({
            theme: "inset-3-dark",
        });
    });
</script>
<script>
    if (
        navigator.userAgent.match(/MSIE 10/i) ||
        navigator.userAgent.match(/Trident\/7\./) ||
        navigator.userAgent.match(/Edge\/12\./)
    ) {
        $("html").on("mousewheel", function() {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
</script>
<app-loading></app-loading>