<div id="news" class="bodyWrap">
    <div class="videoWrap">
        <header class="header">
            <div class="header__navwrap">
                <div class="header__navwrap--topnav">
                    <p class="header__navwrap--topnav--text">
                        新車・中古車・整備・修理
                    </p>
                    <button class="toggle" tabindex="1">
                        <div class="toggle__wrap button-all">
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                        </div>
                    </button>
                </div>
                <nav class="header__navwrap--sidenav">
                    <ul class="header__navwrap--sidenav--list">
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/" title="ホーム" tabindex="2" ontouchstart="">ホーム</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/subscription" title="サブスク" tabindex="2">サブスク</a > </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/about"
                                title="代表挨拶"
                                tabindex="3"
                                ontouchstart=""
                                >代表挨拶</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/service"
                                title="事業内容"
                                tabindex="4"
                                ontouchstart=""
                                >事業内容</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/news"
                                title="お知らせ・イベント情報"
                                tabindex="5"
                                ontouchstart=""
                                >お知らせ・イベント情報</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/car-lineup"
                                title="在庫一覧"
                                tabindex="6"
                                ontouchstart=""
                                >在庫一覧</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/company-info"
                                title="店舗情報"
                                tabindex="7"
                                ontouchstart=""
                                >店舗情報</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a
                                href="/contact"
                                title="お問い合わせ"
                                tabindex="8"
                                ontouchstart=""
                                >お問い合わせ</a
                            >
                        </li>
                        <li
                            class="header__navwrap--sidenav--list--banner instagram__banner"
                        >
                            <a
                                href="https://www.instagram.com/odagiriauto/"
                                target="_blank"
                                rel="”noopener”"
                                title="ODAGIRI AUTO instagram"
                                tabindex="10"
                                ontouchstart=""
                            >
                                <img
                                    src="/assets/img/instagram.svg"
                                    alt="ODAGIRI AUTO instagramアカウント"
                                />
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <section class="news">
            <div class="news__title">
                <p class="title">NEWS・EVENT</p>
                <p class="sub-title">お知らせ・イベント情報</p>
            </div>
            <div class="news__wrap">
                <h2 class="news__wrap--head">{{ information.title }}</h2>

                <p class="news__wrap--sub">
                    <app-category [division]="information.division"></app-category>
                    <span class="news__wrap--sub--day">
                        {{ information.createdat | date: "yy/MM/dd E" }}
                    </span>
                </p>

                <div class="news__wrap--main-img" *ngIf="information.childs.length > 0">
                    <img class="news__wrap--main-img__img" src="{{ information.childs[0].signedUrls[0] }}" alt="{{ information.title }}" />
                </div>

                <div class="news__wrap--content">
                    {{ information.description }}
                </div>
                <div class="flex-container">
                    <div class="flex-container__item" *ngFor="let c of information.childs; let i=index" (click)="onClickThumb(i)">
                        <img class="flex-container__item__img" src="{{ c.signedUrls[0] }}" alt="お知らせ" />
                    </div>
                </div>

                <div>
                    <a class="news--wrap--btn" href="javascript:history.back()" title="戻る" tabindex="16" ontouchstart="">戻る</a
                    >
                </div>
            </div>
            <div class="news__wrap--menu">
                <div class="news__wrap--menu--category">
                    <h3 class="news__wrap--menu--category--title title">
                        CATEGORY
                    </h3>
                    <div class="news__wrap--menu--category--list">
                        <a
                            class="news__wrap--menu--category--list--text"
                            href="/news/news"
                            ontouchstart=""
                            tabindex="16"
                            >お知らせ<span id="news-total"></span
                        ></a>
                    <a class="news__wrap--menu--category--list--text" href="/news/event" ontouchstart="" tabindex="17">イベント情報<span id="event-total"></span
                        ></a>
                    <a class="news__wrap--menu--category--list--text" href="/news/other" ontouchstart="" tabindex="18">その他<span id="outher-total"></span
                        ></a>
                </div>
            </div>
            <div class="news__wrap--menu--archive">
                <h3 class="news__wrap--menu--archive--title title">
                    ARCHIVE
                </h3>
                <div class="news__wrap--menu--archive--list">
                    <a *ngFor="let d of dates" class="news__wrap--menu--archive--list--text" (click)="onClickDate(d)" ontouchstart="">{{ d | date: "yyyy.MM"
                            }}<span id="archive-total"></span
                        ></a>
                </div>
            </div>
    </div>
    </section>
    <app-top-contact></app-top-contact>
    <footer class="footer">
        <p class="footer__copyright">&copy; 2021 ODAGIRI AUTO, INC.</p>
    </footer>
    <video class="video" [muted]="'muted'" autoplay loop playsinline>
            <source src="/assets/img/backgroud_movie.mp4" type="video/mp4" />
        </video>
</div>
<div class="bodyWrap__back--left"></div>
<div class="bodyWrap__back--right"></div>
</div>
<script>
    var timer = 0;
    var currentWidth = window.innerWidth;
    $(window).resize(function() {
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }

        timer = setTimeout(function() {
            location.reload();
        }, 200);
    });
</script>
<script>
    var state = false;
    var scrollpos;
    $(".toggle").on("click", function() {
        $(".toggle").toggleClass("active");
        $(".header__navwrap--sidenav").slideToggle();
    });
</script>
<script>
    var $scrollInt = $("#scroll").offset().top;

    const myFunc = function() {
        var elem = document.getElementById("scroll");
        var wh = window.innerHeight;
        elem.style.height = wh + "px";
    };

    window.addEventListener("load", myFunc);
    window.addEventListener("resize", myFunc);
</script>
<script>
    (function($) {
        $(".scroll-area").mCustomScrollbar();
    })(jQuery);
</script>
<script>
    if (
        navigator.userAgent.match(/MSIE 10/i) ||
        navigator.userAgent.match(/Trident\/7\./) ||
        navigator.userAgent.match(/Edge\/12\./)
    ) {
        $("html").on("mousewheel", function() {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
</script>
<app-loading></app-loading>