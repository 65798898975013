import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === 'event') {
      return 'EVENT';
    } if (value === 'news') {
      return 'NEWS';
    } if (value === 'stock') {
      return 'STOCK';
    } if (value === 'other') {
      return 'OTHER';
    }

    return '';
  }

}
