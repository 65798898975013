<div class="bodyWrap">
    <div class="videoWrap">
        <header class="header">
            <div class="header__navwrap">
                <div class="header__navwrap--topnav">
                    <p class="header__navwrap--topnav--text">新車・中古車・整備・修理</p>
                    <button class="toggle button-all" tabindex="1">
                        <div class="toggle__wrap">
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                        </div>
                    </button>
                </div>
                <nav class="header__navwrap--sidenav">
                    <ul class="header__navwrap--sidenav--list">
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/" title="ホーム" tabindex="2" ontouchstart="">ホーム</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/subscription" title="サブスク" tabindex="2">サブスク</a > </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/about" title="代表挨拶" tabindex="3" ontouchstart="">代表挨拶</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/service" title="事業内容" tabindex="4" ontouchstart="">事業内容</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/news" title="お知らせ・イベント情報" tabindex="5" ontouchstart="">お知らせ・イベント情報</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/car-lineup" title="在庫一覧" tabindex="6" ontouchstart="">在庫一覧</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/company-info" title="店舗情報" tabindex="7" ontouchstart="">店舗情報</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/contact" title="お問い合わせ" tabindex="8" ontouchstart="">お問い合わせ</a>
                        </li>
                        <li class="header__navwrap--sidenav--list--banner instagram__banner">
                            <a href="https://www.instagram.com/odagiriauto/" target="_blank" rel="”noopener”" title="ODAGIRI AUTO instagram" tabindex="10" ontouchstart="">
                                <img src="/assets/img/instagram.svg" alt="ODAGIRI AUTO instagramアカウント" />
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <main>
            <section class="carIndex">
                <div class="carIndex__title">
                    <p class="title">CAR LINEUP</p>
                    <p class="sub-title">在庫一覧</p>
                </div>
                <div class="carIndex__wrap">
                    <a class="carIndex__wrap--link" href="/car-lineup/{{ information.id }}" *ngFor="let information of informations">
                        <div class="carIndex__wrap--link--img">
                            <img class="carIndex__wrap--link--img--img" src="{{ information.childs[0].signedUrls[0] }}" alt="{{ information.title }}">
                        </div>
                        <div class="carIndex__wrap--link--info">
                            <p class="carIndex__wrap--link--info--brand">{{ information.brand }}</p>
                            <p class="carIndex__wrap--link--info--name">{{ information.title }}</p>
                            <div class="carIndex__wrap--link--info--box1">
                                <p class="carIndex__wrap--link--info--box1--text">{{ information.carType }}</p>
                                <p class="carIndex__wrap--link--info--box1--text">{{ information.mission }}</p>
                                <p class="carIndex__wrap--link--info--box1--text">{{ information.color }}</p>
                                <p class="carIndex__wrap--link--info--box1--text">{{ information.warranty }}</p>
                            </div>
                            <div class="carIndex__wrap--link--info--box2">
                                <div class="carIndex__wrap--link--info--box3">
                                    <div class="carIndex__wrap--link--info--box3--inner">
                                        <p class="carIndex__wrap--link--info--box3--inner--title">年式</p>
                                        <p class="carIndex__wrap--link--info--box3--inner--grade">{{ information.modelYear }}</p>
                                    </div>
                                    <div class="carIndex__wrap--link--info--box3--inner">
                                        <p class="carIndex__wrap--link--info--box3--inner--title">走行距離</p>
                                        <p class="carIndex__wrap--link--info--box3--inner--grade">{{ information.mileage }}</p>
                                    </div>
                                    <div class="carIndex__wrap--link--info--box3--inner">
                                        <p class="carIndex__wrap--link--info--box3--inner--title">排気量</p>
                                        <p class="carIndex__wrap--link--info--box3--inner--grade">{{ information.displacement }}</p>
                                    </div>
                                    <div class="carIndex__wrap--link--info--box3--inner">
                                        <p class="carIndex__wrap--link--info--box3--inner--title">車検有無</p>
                                        <p class="carIndex__wrap--link--info--box3--inner--grade">{{ information.inspection }}</p>
                                    </div>
                                    <div class="carIndex__wrap--link--info--box3--inner">
                                        <p class="carIndex__wrap--link--info--box3--inner--title">修復歴</p>
                                        <p class="carIndex__wrap--link--info--box3--inner--grade">{{ information.repair }}</p>
                                    </div>
                                </div>
                                <div class="carIndex__wrap--link--info--price">
                                    <div class="carIndex__wrap--link--info--price--box">
                                        <span class="carIndex__wrap--link--info--price--box--text">本体価格</span>
                                        <span class="carIndex__wrap--link--info--price--box--number">{{ information.price }}</span>
                                        <span class="carIndex__wrap--link--info--price--box--en">万円</span>
                                    </div>
                                    <div class="carIndex__wrap--link--info--price--box">
                                        <span class="carIndex__wrap--link--info--price--box--text">支払総額(税込）</span>
                                        <span class="carIndex__wrap--link--info--price--box--number">{{ information.priceTotal }}</span>
                                        <span class="carIndex__wrap--link--info--price--box--en">万円</span>
                                    </div>
                                </div>
                            </div>
                            <a class="carIndex__wrap--link--info--btn" href="/car-lineup/{{ information.id }}" title="READ MORE" tabindex="11" ontouchstart="">READ MORE</a>
                        </div>
                    </a>
                </div>
            </section>
            <app-top-contact></app-top-contact>
        </main>
        <footer class="footer">
            <p class="footer__copyright">&copy; 2021 ODAGIRI AUTO, INC.</p>
        </footer>
        <video class="video" [muted]="'muted'" autoplay loop playsinline>
            <source src="/assets/img/backgroud_movie.mp4" type="video/mp4">
        </video>
    </div>
    <div class="bodyWrap__back--left"></div>
    <div class="bodyWrap__back--right"></div>
</div>
<script>
    var timer = 0;
    var currentWidth = window.innerWidth;
    $(window).resize(function() {
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }

        timer = setTimeout(function() {
            location.reload();
        }, 200);

    });
</script>
<script>
    var state = false;
    var scrollpos;
    $('.toggle').on('click', function() {
        $('.toggle').toggleClass('active');
        $('.header__navwrap--sidenav').slideToggle();
    });
</script>
<script>
    var $scrollInt = $("#scroll").offset().top;

    const myFunc = (function() {
        var elem = document.getElementById('scroll');
        var wh = window.innerHeight;
        elem.style.height = wh + 'px';
    });

    window.addEventListener('load', myFunc);
    window.addEventListener('resize', myFunc);
</script>
<script>
    (function($) {
        $(".scroll-area").mCustomScrollbar();
    })(jQuery);
</script>
<script>
    if (navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
        $('html').on("mousewheel", function() {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
</script>
<app-loading></app-loading>