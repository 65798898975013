import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { Information, InformationChild, setSignedUrl } from 'src/app/models/information';
import { InformationService } from '../../services/information.service';

import * as moment from 'moment';

@Component({
  selector: 'app-news-show',
  templateUrl: './news-show.component.html',
  styleUrls: ['./news-show.component.scss']
})
export class NewsShowComponent implements OnInit {

  dates = Array();
  information: Information = { childs: [] } as Information;
  private _albums = [];

  constructor(
    private route: ActivatedRoute,
    private informationService: InformationService,
    private _lightbox: Lightbox
    ) {
      this.dates.push(new Date());

      for (let i = 0; i < 11; i++) {
        this.dates.push(moment().add(-1 - i, 'month'));
      }
  }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.information = await this.informationService.getInformation(id);

    const urls = await setSignedUrl(this.information);
    for (let i in urls) {
      this.information.childs[i].signedUrls.push(urls[i]);
    }

    for (let i in this.information.childs) {
      const src = this.information.childs[i].signedUrls[0];
      let album = {
        src: src,
        caption: '',
        thumb: ''
      };

      this._albums.push(album);
    }
  }

  onClickThumb(i: number) {
    this._lightbox.open(this._albums, i);
  }
}
