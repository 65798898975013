<span
    [ngClass]="
        division == 'news'
            ? 'category-news'
            : division == 'event'
            ? 'category-event'
            : division == 'stock'
            ? 'category-stock'
            : 'category-other'
    "
    class="header__news--wrap--list--item--link--category news__wrap--sub--category"
>
    {{ division | category }}
</span>
