<div class="bodyWrap">
    <div class="videoWrap">
        <header class="header">
            <div class="header__navwrap">
                <div class="header__navwrap--topnav">
                    <p class="header__navwrap--topnav--text">
                        新車・中古車・整備・修理
                    </p>
                    <button class="toggle button-all" tabindex="1">
                        <div class="toggle__wrap">
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                            <span class="toggle__line"></span>
                        </div>
                    </button>
                </div>
                <nav class="header__navwrap--sidenav">
                    <ul class="header__navwrap--sidenav--list">
                        <li class="header__navwrap--sidenav--list--item">
                            <a href="/" title="ホーム" tabindex="2" ontouchstart="">ホーム</a
                            >
                        </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/subscription" title="サブスク" tabindex="2">サブスク</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/about" title="代表挨拶" tabindex="3" ontouchstart="" >代表挨拶</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/service" title="事業内容" tabindex="4" ontouchstart="" >事業内容</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/news" title="お知らせ・イベント情報" tabindex="5" ontouchstart="" >お知らせ・イベント情報</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/car-lineup" title="在庫一覧" tabindex="6" ontouchstart="" >在庫一覧</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/company-info" title="店舗情報" tabindex="7" ontouchstart="" >店舗情報</a > </li>
                        <li class="header__navwrap--sidenav--list--item"> <a href="/contact" title="お問い合わせ" tabindex="8" ontouchstart="" >お問い合わせ</a > </li>
                        <li class="header__navwrap--sidenav--list--banner instagram__banner" > <a href="https://www.instagram.com/odagiriauto/" target="_blank" rel="”noopener”" title="ODAGIRI AUTO instagram" tabindex="10" ontouchstart="" > <img src="/assets/img/instagram.svg" alt="ODAGIRI AUTO instagramアカウント" /> </a>                            </li>
                    </ul>
                </nav>
            </div>
        </header>
        <section class="service">
            <div class="service__title">
                <p class="title">SERVICE</p>
                <p class="sub-title">事業内容</p>
            </div>
            <div class="service__wrap">
                <div class="service__wrap--inner">
                    <ul class="service__wrap--inner--list grid-01">
                        <li class="service__wrap--inner--list--item">
                            <div class="service__wrap--inner--list--item--img service-01"></div>
                            <div class="service__wrap--inner--list--item--contents">
                                <h2 class="service__wrap--inner--list--item--contents--title">
                                    新車販売
                                </h2>
                                <p class="service__wrap--inner--list--item--contents--text">
                                    国産全メーカーの新車を幅広く取り扱っております
                                </p>
                            </div>
                        </li>
                    </ul>
                    <ul class="service__wrap--inner--list grid-02">
                        <li class="service__wrap--inner--list--item">
                            <div class="service__wrap--inner--list--item--img service-02"></div>
                            <div class="service__wrap--inner--list--item--contents">
                                <h2 class="service__wrap--inner--list--item--contents--title">
                                    中古車販売・買取・委託販売
                                </h2>
                                <p class="service__wrap--inner--list--item--contents--text">
                                    国産全メーカーの新車を幅広く取り扱っております
                                </p>
                            </div>
                        </li>
                    </ul>
                    <ul class="service__wrap--inner--list grid-03">
                        <li class="service__wrap--inner--list--item">
                            <div class="service__wrap--inner--list--item--img service-03"></div>
                            <div class="service__wrap--inner--list--item--contents">
                                <h2 class="service__wrap--inner--list--item--contents--title">
                                    車検
                                </h2>
                                <p class="service__wrap--inner--list--item--contents--text">
                                    国産全メーカーの新車を幅広く取り扱っております
                                </p>
                            </div>
                        </li>
                    </ul>
                    <ul class="service__wrap--inner--list grid-04">
                        <li class="service__wrap--inner--list--item">
                            <div class="service__wrap--inner--list--item--img service-04"></div>
                            <div class="service__wrap--inner--list--item--contents">
                                <h2 class="service__wrap--inner--list--item--contents--title">
                                    鈑金・塗装
                                </h2>
                                <p class="service__wrap--inner--list--item--contents--text">
                                    国産全メーカーの新車を幅広く取り扱っております
                                </p>
                            </div>
                        </li>
                    </ul>
                    <ul class="service__wrap--inner--list grid-05">
                        <li class="service__wrap--inner--list--item">
                            <div class="service__wrap--inner--list--item--img service-05"></div>
                            <div class="service__wrap--inner--list--item--contents">
                                <h2 class="service__wrap--inner--list--item--contents--title">
                                    保険業務・事故対応
                                </h2>
                                <p class="service__wrap--inner--list--item--contents--text">
                                    国産全メーカーの新車を幅広く取り扱っております
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="service__wrap--rental">
                    <div class="service__wrap--rental--img rental-06"></div>
                    <div class="service__wrap--rental--contents">
                        <h2 class="service__wrap--rental--contents--title">
                            レンタカー
                        </h2>
                        <p class="service__wrap--rental--contents--text">
                            軽自動車、コンパクトカー、ワゴン、ミニバンなど幅広く取り扱っております。
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <app-top-contact></app-top-contact>
        <footer class="footer">
            <p class="footer__copyright">&copy; 2021 ODAGIRI AUTO, INC.</p>
        </footer>
        <video class="video" [muted]="'muted'" autoplay loop playsinline>
            <source src="/assets/img/backgroud_movie.mp4" type="video/mp4" />
        </video>
    </div>
    <div class="bodyWrap__back--left"></div>
    <div class="bodyWrap__back--right"></div>
</div>
<script>
    var timer = 0;
    var currentWidth = window.innerWidth;
    $(window).resize(function() {
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }

        timer = setTimeout(function() {
            location.reload();
        }, 200);
    });
</script>
<script>
    var state = false;
    var scrollpos;
    $(".toggle").on("click", function() {
        $(".toggle").toggleClass("active");
        $(".header__navwrap--sidenav").slideToggle();
    });
</script>
<script>
    var $scrollInt = $("#scroll").offset().top;

    const myFunc = function() {
        var elem = document.getElementById("scroll");
        var wh = window.innerHeight;
        elem.style.height = wh + "px";
    };

    window.addEventListener("load", myFunc);
    window.addEventListener("resize", myFunc);
</script>
<script>
    (function($) {
        $(".scroll-area").mCustomScrollbar();
    })(jQuery);
</script>
<script>
    if (
        navigator.userAgent.match(/MSIE 10/i) ||
        navigator.userAgent.match(/Trident\/7\./) ||
        navigator.userAgent.match(/Edge\/12\./)
    ) {
        $("html").on("mousewheel", function() {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
</script>