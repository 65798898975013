import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(".toggle").on("click", function () {
      $(".toggle").toggleClass("active");
      $(".videoWrap").toggleClass("open");
      $(".header__navwrap--sidenav").slideToggle();
    });

    // $(".scroll-area").mCustomScrollbar();
  }
}
