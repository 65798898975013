import { Injectable } from '@angular/core';
import { Authentication } from '../models/authentication';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private auth: Authentication = null;
  private loadingFlag = false;

  constructor() { }

  set authentication(authentication: Authentication) {
    this.auth = authentication;
  }

  get authentication() {
    return this.auth;
  }

  set loading(loading) {
    this.loadingFlag = loading;
  }

  get loading(): boolean {
    return this.loadingFlag;
  }
}
